.App {
  text-align: center;
}

.boxContainer {
  display: flex;
  justify-content: center;
 }

.navMenu {
  padding:5px 0px;
  font-family: Helvetica, "Trebuchet MS", Verdana, sans-serif;
}

.box {
  width: 45px;
  height: 45px;
  background-color: #FCCD4B;
  margin: 300px 0px 0px;
  border: 1px solid #FFE173;
}
